@import "variables";

#root {
  width: 100%;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
  color: $color-black;

  &:hover{
    cursor: pointer;
  }
}

h1, h2, h3 {
  text-transform: uppercase;
}

button {
  cursor: pointer;
  border: none;
}

*:focus {
  outline: none;
}

