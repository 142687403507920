@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html {
  color: $color-black;
  font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 600;
  font-size: 2.8rem;
}

h3 {
  font-weight: 600;
  font-size: 1.8rem;
}

p {
  font-size: 1.6rem;
  letter-spacing: .03571rem;
  line-height: 2.6rem;
}







