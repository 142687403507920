$color-black: #000000;
$color-white: #FFFFFF;
$color-primary: #262D51;
$color-primary-lighten: #f7b70b;

$bp-extra-small: 600px;
$bp-small: 768px;
$bp-medium: 992px;
$bp-large: 1200px;
$bp-extra-large: 1440px;







